import React, { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { db as firestore, storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import { PlusCircle, Pencil, Trash2, Eye } from "lucide-react";

export default function ArticleTable() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const articlesPerPage = 20;

  const navigate = useNavigate();

  // Fetch articles from Firestore
  const fetchArticles = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(firestore, "articles"));
      const articlesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Default sort by date (most recent first)
      const sortedArticles = articlesData.sort((a, b) => {
        const aDate = a.createdAt?.seconds || 0;
        const bDate = b.createdAt?.seconds || 0;
        return bDate - aDate; // Descending order
      });

      setArticles(sortedArticles);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  const sortArticles = (field) => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);

    const sortedArticles = [...articles].sort((a, b) => {
      const aValue = a[field] || ""; // Handle undefined/null fields
      const bValue = b[field] || "";

      if (direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    setArticles(sortedArticles);
  };

  const deleteArticle = async (id, imageUrl) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        await deleteDoc(doc(firestore, "articles", id));
        if (imageUrl) {
          const imageRef = ref(
            storage,
            decodeURIComponent(imageUrl.split("/o/")[1].split("?")[0])
          );
          await deleteObject(imageRef);
        }
        alert("Article and associated image deleted successfully!");
        fetchArticles();
      } catch (error) {
        console.error("Error deleting article or image:", error);
      }
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const totalPages = Math.ceil(articles.length / articlesPerPage);
  const currentArticles = articles.slice(
    (currentPage - 1) * articlesPerPage,
    currentPage * articlesPerPage
  );

  return (
    <div className="p-8 bg-gray-100 w-5/6 min-h-screen flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-primary text-black">
          Article Management
        </h1>
        <button
          onClick={() => navigate("/ArticleForm")}
          className="bg-black text-white px-1 md:px-4 py-1 rounded-md hover:bg-blue-60 gap-2 font-bold text-primary hover:bg-blue-900"
        >
          <div className="flex justify-center gap-2 items-center p-1">
            <PlusCircle className="h-4 w-4" />
            <span className="hidden md:inline">Add New</span>
          </div>
        </button>
      </div>

      {loading ? (
        <p className="text-gray-600">Loading articles...</p>
      ) : (
        <div className="flex-grow overflow-x-auto">
          <table className="min-w-full bg-white border rounded-md shadow-md">
            <thead className="bg-black text-white  ">
              <tr>
                <th
                  className="px-4 py-2 text-left cursor-pointer font-semibold"
                  onClick={() => sortArticles("title")}
                >
                  Title{" "}
                  {sortField === "title" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="px-4 py-2 text-left cursor-pointer font-semibold"
                  onClick={() => sortArticles("authorId")}
                >
                  Author ID{" "}
                  {sortField === "authorId" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="px-4 py-2 text-left cursor-pointer font-semibold"
                  onClick={() => sortArticles("alias")}
                >
                  Alias{" "}
                  {sortField === "alias" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="px-4 py-2 text-left cursor-pointer font-semibold"
                  onClick={() => sortArticles("createdAt")}
                >
                  Date{" "}
                  {sortField === "createdAt" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="px-4 py-2 text-left cursor-pointer font-semibold"
                  onClick={() => sortArticles("status")}
                >
                  Status{" "}
                  {sortField === "status" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th className="px-4 py-2 text-left font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentArticles.map((article) => (
                <tr key={article.id} className="border-b hover:bg-gray-100">
                  <td className="px-4 py-2">{article.title}</td>
                  <td className="px-4 py-2">{article.authorId || "N/A"}</td>
                  <td className="px-4 py-2">{article.alias || "N/A"}</td>
                  <td className="px-4 py-2">
                    {article.createdAt
                      ? new Date(
                          article.createdAt.seconds * 1000
                        ).toLocaleDateString()
                      : "N/A"}
                  </td>
                  <td className="capitalize">
                    <div
                      className={`inline-block px-4 py-1 rounded-md text-white font-semibold text-sm ${
                        article.status === "published"
                          ? "bg-green-500"
                          : "bg-yellow-500"
                      }`}
                    >
                      <p>{article.status}</p>
                    </div>
                  </td>
                  <td className="px-4 py-2 flex space-x-2">
                    <button
                      onClick={() => navigate(`/edit/${article.id}`)}
                      className="border border-black bg-white p-2 rounded-md hover:bg-blue-600"
                    >
                      <Pencil className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => deleteArticle(article.id, article.image)}
                      className=" bg-red-500 border border-black text-white p-2 rounded-md hover:bg-red-600"
                    >
                      <Trash2 className="h-4 w-4"></Trash2>
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/article/${article.id}`,
                          "_blank"
                        )
                      }
                      className="bg-white border border-black text-green-500  p-2 rounded-md hover:bg-green-600"
                    >
                      <Eye className="h-4 w-4"></Eye>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
}
