import React, { useState } from "react";
import FormatText from "../helper/TextFormat";

export default function SWOTAnalysis({
  strengthsAnalysis,
  weaknessAnalysis,
  opportunitiesAnalysis,
  threatsAnalysis,
  recommendSummaryText,
}) {
  const [activeTab, setActiveTab] = useState("S"); // Default to Strengths

  const tabs = [
    { key: "S", label: "Strengths", content: strengthsAnalysis },
    { key: "W", label: "Weaknesses", content: weaknessAnalysis },
    { key: "O", label: "Opportunities", content: opportunitiesAnalysis },
    { key: "T", label: "Threats", content: threatsAnalysis },
    { key: "R", label: "Recommendation", content: recommendSummaryText },
  ];

  return (
    <div className="px-1 md:px-6 lg:px-8 py-8">
      <h1
        className="text-center w-full text-2xl md:text-4xl font-semibold text-[#1E90FF] pb-4 font-montserrat relative
    before:absolute before:-inset-1 before:rounded-sm before:blur-lg before:bg-[#1E90FF] before:opacity-20 before:content-['']"
      >
        S.W.O.T Analysis & Business Recommendation
      </h1>
      {/* Responsive Layout */}
      <div className="block md:hidden">
        {/* Dropdown for Small Screens */}
        <select
          onChange={(e) => setActiveTab(e.target.value)}
          value={activeTab}
          className="w-full p-2 border border-gray-300 rounded-lg text-gray-700 bg-white focus:ring-2 focus:ring-[#1E90FF]"
        >
          {tabs.map((tab) => (
            <option key={tab.key} value={tab.key}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden md:flex justify-center space-x-4 border-b border-gray-300 pb-2">
        {/* Tabs for Medium and Larger Screens */}
        {tabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={`px-4 py-2 font-semibold text-lg ${
              activeTab === tab.key
                ? "text-[#1E90FF] border-b-2 border-[#1E90FF]"
                : "text-gray-500 hover:text-[#1E90FF]"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {/* Tab Content */}
      <div className="mt-6">
        {tabs.map(
          (tab) =>
            activeTab === tab.key && (
              <div key={tab.key}>
                {/* <p>{tab.content}</p> */}
                <FormatText inputText={tab.content} />
              </div>
            )
        )}
      </div>
    </div>
  );
}
