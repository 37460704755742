import React from "react";
import Navbar from "./../component/Navbar";
import { FaInstagram, FaLinkedin, FaEnvelope } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="bg-black items-center">
      <Navbar></Navbar>

      <div className="bg-white shadow-lg rounded-lg p-8 text-center m-4">
        <h1 className="text-4xl font-bold mb-4 text-gray-800">Contact Us</h1>
        <p className="text-gray-600 text-lg mb-6 text-start">
          We'd love to hear from you! Whether you have a question about our
          services, need assistance, or just want to give feedback, feel free to
          reach out to us through any of the platforms below.
        </p>

        <div className="flex justify-around mt-8">
          {/* Instagram */}
          <a
            href="https://www.instagram.com/lokavo.id/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center"
          >
            <FaInstagram className="text-[#1E90FF] hover:text-[#3482d0ae] text-6xl mb-2" />
            <span className="text-gray-600 text-lg">Instagram</span>
          </a>

          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/company/lokavo-id/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center"
          >
            <FaLinkedin className="text-[#1E90FF] hover:text-[#3482d0ae]  text-6xl mb-2" />
            <span className="text-gray-600 text-lg">LinkedIn</span>
          </a>

          {/* Email */}
          <a
            href="mailto:lokavo.localventures@gmail.com;"
            className="flex flex-col items-center"
          >
            <FaEnvelope className="text-[#1E90FF] hover:text-[#3482d0ae]  text-6xl mb-2" />
            <span className="text-gray-600 text-lg">Email</span>
          </a>
        </div>

        <div className="mt-8">
          <p className="text-gray-600 text-lg">
            You can also drop us an email at:
            <a
              href="mailto:lokavo.localventures@gmail.com;"
              className="text-[#1E90FF] hover:text-[#3482d0ae]  font-semibold ml-1"
            >
              lokavo.localventures@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
