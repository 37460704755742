import React, { useEffect, useState } from "react";
import { Users, UserCog, FileText, FileEdit } from "lucide-react";
import Footer from "./PageFooter";
import { collection, getDocs } from "firebase/firestore";
import { db as firestore } from "../firebase";

export default function AdminStats() {
  const [loading, setLoading] = useState(true);
  const [userCount, setUserCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [publishedCount, setPublishedCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);

  // Reference to the 'users' collection
  const usersCollection = collection(firestore, "users");

  // Fetch users from Firestore
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(usersCollection);
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const admins = usersData.filter((admin) => admin.role === "admin").length;
      const users = usersData.filter((user) => user.role === "user").length;

      setAdminCount(admins);
      setUserCount(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch articles from Firestore
  const fetchArticles = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(firestore, "articles"));
      const articlesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const published = articlesData.filter(
        (article) => article.status === "published"
      ).length;
      const draft = articlesData.filter(
        (article) => article.status === "draft"
      ).length;

      setPublishedCount(published);
      setDraftCount(draft);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
    fetchUsers();
  }, []);

  return (
    <div className="p-8 bg-gray-100 w-5/6 min-h-screen flex flex-col">
      <h1 className="text-3xl font-bold mb-8">Admin Dashboard</h1>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        {/* Total Users */}
        <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex items-center justify-between pb-2">
            <h2 className="text-sm font-medium">Total Users</h2>
            <Users className="h-6 w-6 text-gray-400" />
          </div>
          <div>
            <p className="text-2xl font-bold">{userCount}</p>
            <p className="text-xs text-gray-500">Registered users</p>
          </div>
        </div>

        {/* Total Admins */}
        <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex items-center justify-between pb-2">
            <h2 className="text-sm font-medium">Total Admins</h2>
            <UserCog className="h-6 w-6 text-gray-400" />
          </div>
          <div>
            <p className="text-2xl font-bold">{adminCount}</p>
            <p className="text-xs text-gray-500">System administrators</p>
          </div>
        </div>

        {/* Published Articles */}
        <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex items-center justify-between pb-2">
            <h2 className="text-sm font-medium">Published Articles</h2>
            <FileText className="h-6 w-6 text-gray-400" />
          </div>
          <div>
            <p className="text-2xl font-bold">{publishedCount}</p>
            <p className="text-xs text-gray-500">Live on the site</p>
          </div>
        </div>

        {/* Draft Articles */}
        <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex items-center justify-between pb-2">
            <h2 className="text-sm font-medium">Draft Articles</h2>
            <FileEdit className="h-6 w-6 text-gray-400" />
          </div>
          <div>
            <p className="text-2xl font-bold">{draftCount}</p>
            <p className="text-xs text-gray-500">Pending publication</p>
          </div>
        </div>
      </div>
      <div className="flex-1"></div>
      <Footer textColor="black" bgColor="white"></Footer>
    </div>
  );
}
