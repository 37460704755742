import React from "react";
import ArticleEditor from "../component/ArticleEditor";

import { useAdminCheck } from "../helper/CheckAuth";
import { auth } from "../firebase";

const ArticleForm = () => {
  const { isAdmin, loading } = useAdminCheck();

  const currentUser = auth.currentUser;
  return <ArticleEditor user={currentUser}></ArticleEditor>;
};

export default ArticleForm;
