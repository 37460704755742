import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../component/Navbar";
import Footer from "../component/PageFooter";

const PricingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-black ">
      <Navbar></Navbar>
      <div className="container mx-auto px-4">
        {/* Title */}
        <h1 className="text-4xl font-bold text-center text-white mb-8">
          Paket Harga
        </h1>
        <p className="text-center text-white mb-12">
          Pilih model langganan atau hubungi kami untuk kemitraan khusus.
        </p>

        {/* Subscription Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Low Tier */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">Low Tier</h3>
            <p className="text-gray-600 mb-6">Rp. 50.000,00 per 30 Token</p>
            <p className="text-sm text-gray-500 mb-4">
              <i>Affordable for small-scale users.</i>
              <br></br>Terjangkau untuk pengguna skala kecil.
            </p>
            <div className="mt-auto"></div>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              onClick={() => navigate("/Contact")}
            >
              Pilih Paket
            </button>
          </div>

          {/* Mid Tier */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">Mid Tier</h3>
            <p className="text-gray-600 mb-6">Rp. 100.000,00 per 70 Token</p>
            <p className="text-sm text-gray-500 mb-4">
              <i>Ideal for medium-scale operations.</i>
              <br></br>Ideal untuk operasi skala menengah.
            </p>
            <div className="mt-auto"></div>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              onClick={() => navigate("/Contact")}
            >
              Pilih Paket
            </button>
          </div>

          {/* High Tier */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">High Tier</h3>
            <p className="text-gray-600 mb-6">Rp. 150.000,00 per 150 Token</p>
            <p className="text-sm text-gray-500 mb-4">
              <i>Perfect for high-volume users.</i>
              <br></br>Sempurna untuk pengguna dengan volume tinggi.
            </p>
            <div className="mt-auto"></div>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              onClick={() => navigate("/Contact")}
            >
              Pilih Paket
            </button>
          </div>

          {/* Pay As You Go */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">
              Pay As You Go
            </h3>
            <p className="text-gray-600 mb-6">Hubungi kami melalui email</p>
            <p className="text-sm text-gray-500 mb-4">
              <i>Flexible for occasional users.</i>
              <br></br>Fleksibel untuk pengguna sesekali.
            </p>
            <div className="mt-auto"></div>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              onClick={() => navigate("/Contact")}
            >
              Hubungi Kami
            </button>
          </div>
        </div>

        {/* Partnership Section */}
        <div className="mt-16 bg-blue-100 p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">
            Kemitraan dengan Penyedia Tempat Usaha
          </h2>
          <p className="text-center text-gray-600">
            Penyedia tempat usaha dapat membayar akses ke analisis Lokavo guna
            menarik bisnis F&B ke lokasi mereka.
          </p>
          <p className="text-center text-gray-500 mt-4">
            Business location providers can pay for Lokavo’s analytics to
            attract F&B businesses to their locations.
          </p>
          <div className="text-center mt-6">
            <button
              className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
              onClick={() => navigate("/Contact")}
            >
              Hubungi Kami
            </button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PricingPage;
