import React, { useRef } from "react";
import Navbar from "../component/Navbar";
import Hero from "../component/Hero";
import Analytics from "../component/Analytics";
import Footer from "../component/PageFooter";
import QAComponent from "../component/QA";

import SignInAndStore from "../component/SignInAndStoreTrial";

export default function Home() {
  const mapsRef = useRef(null); // Create a reference for MapsDisplay

  // Function to scroll to the MapsDisplay section
  const scrollToMaps = () => {
    if (mapsRef.current) {
      mapsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Navbar></Navbar>
      <Hero scrollToMaps={scrollToMaps}></Hero>
      <Analytics></Analytics>

      <div ref={mapsRef}>
        <SignInAndStore></SignInAndStore>
      </div>
      <QAComponent></QAComponent>
      <Footer></Footer>
    </div>
  );
}
