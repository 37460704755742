import React from "react";
import { FaInstagram, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = ({ textColor = "white", bgColor = "black" }) => {
  const navigate = useNavigate();
  return (
    <footer className={`bg-${bgColor} py-8 mt-8 `}>
      <div className="flex justify-center space-x-8">
        {/* Instagram */}
        <a
          href="https://www.instagram.com/lokavo.id/"
          target="_blank"
          rel="noopener noreferrer"
          className={`text-${textColor} text-3xl hover:text-[#1E90FF]`}
        >
          <FaInstagram />
        </a>

        {/* LinkedIn */}
        <a
          href="https://www.linkedin.com/company/lokavo-id/"
          target="_blank"
          rel="noopener noreferrer"
          className={`text-${textColor} text-3xl hover:text-[#1E90FF]`}
        >
          <FaLinkedin />
        </a>

        {/* Email */}
        <a
          href="mailto:lokavo.localventures@gmail.com;"
          className={`text-${textColor} text-3xl hover:text-[#1E90FF]`}
        >
          <FaEnvelope />
        </a>
      </div>

      <div
        className={`text-${textColor} text-center mt-4 hover:text-[#1E90FF]`}
      >
        <p>&copy; 2024 Lokavo. All rights reserved.</p>
        <a
          href={() => navigate("/privacy-policy")}
          onClick={() => navigate("/privacy-policy")}
          className={`text-${textColor} text-center mt-2 hover:text-[#1E90FF]`}
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
