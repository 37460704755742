import React from "react";
import Navbar from "./../component/Navbar";
import Footer from "../component/PageFooter";
import Adit from "../assets/team/adit.png";
import Felix from "../assets/team/felix.png";
import Fachri from "../assets/team/fachri.png";
import Irfan from "../assets/team/irfan.png";
import Krisna from "../assets/team/krisna.png";
import Naufal from "../assets/team/naufal.png";
import Logo from "../assets/lokavologo.png";

const About = () => {
  const teamMembers = [
    {
      name: "FELIX",
      position: "Chief Executive Officer",
      image: Felix, // Replace with your image path
    },
    {
      name: "Fachri Kurniansyah",
      position: "Chief Financial Officer",
      image: Fachri, // Replace with your image path
    },
    {
      name: "Naufal Ulwan Arrifqy",
      position: "Chief Operating Officer",
      image: Naufal, // Replace with your image path
    },
    {
      name: "Rizky Aditya",
      position: "Chief Marketing Officer",
      image: Adit, // Replace with your image path
    },
    {
      name: "Krisna Diva",
      position: "Chief Technology Officer",
      image: Krisna, // Replace with your image path
    },
    {
      name: "Muhammad Saifullah Irfanuddin",
      position: "Product Manager",
      image: Irfan, // Replace with your image path
    },
    // Add more team members as needed
  ];

  return (
    <div className="bg-black items-center">
      <Navbar></Navbar>

      <div className="bg-white shadow-lg rounded-lg p-8 text-center m-4">
        <img src={Logo} alt="logo" />
        <h1 className="text-4xl font-bold mb-4 text-gray-800">About Us</h1>

        <p className="text-gray-600 text-lg mb-6 text-start">
          Lokavo is a business intelligence Start-Up platform that provides
          location analysis services for business sites and competitor analysis
          for entrepreneurs, MSMEs, and business owners in Indonesia. In support
          of Indonesia Emas 2045, we aim to become a Start-Up that can
          contribute to the F&B sector in Indonesia. The growth of various types
          of F&B businesses presents an opportunity to boost the country’s
          revenue in the F&B industry. However, poor decision-making can affect
          the sustainability of an F&B business. Therefore, we hope our services
          can help F&B businesses in Indonesia grow even larger and create new
          job opportunities for the people of Indonesia!
        </p>
        <p className="text-gray-600 text-lg mb-6 text-start">
          Lokavo adalah Start-Up business intelligence platform yang menyediakan
          layanan analisis lokasi tempat usaha dan analisis kompetitor bagi
          wirausaha, UMKM, dan pebisnis di Indonesia. Demi mengusung Indonesia
          Emas 2045, kami memiliki tujuan menjadi sebuah Start-Up yang dapat
          berkontribusi pada sektor F&B di Indonesia. Berkembangnya
          bermacam-macam jenis usaha F&B menjadi peluang untuk mengembangkan
          pendapatan negara di bidang industri F&B. Namun, pengambilan keputusan
          yang kurang tepat akan berdampak pada keberlanjutan sebuah F&B. Oleh
          karena itu, kami berharap layanan kami dapat membantu usaha F&B di
          Indonesia berkembang lebih besar lagi dan bisa membuka lapangan usaha
          baru bagi masyarakat di Indonesia!
        </p>
        <h4 className="font-bold text-[#1E90FF] md:text-5xl sm:text-3xl text:4xl ">
          "Lokavo, Optimize Your Business, One Click at a Time!"
        </h4>

        <div className="md:flex md:justify-around mt-8">
          <div className="md:w-64">
            {/* <img
              src="https://via.placeholder.com/150"
              alt="Our Mission"
              className="rounded-lg mb-4"
            /> */}
            <h2 className="text-2xl font-semibold mb-2 text-[#1E90FF]">
              Our Mission
            </h2>
            <p className="text-gray-600">
              Providing detailed analysis by offering in-depth insights into
              business locations that help entrepreneurs understand the
              potential and challenges of specific areas, including competition
              assessment and market needs, while supporting strategic decisions
              with relevant data and insights to assist entrepreneurs in
              determining the best location to open a business or the best
              businesses for a specific location.
            </p>
          </div>
          <div className="md:w-64">
            {/* <img
              src="https://via.placeholder.com/150"
              alt="Our Vision"
              className="rounded-lg mb-4"
            /> */}
            <h2 className="text-2xl font-semibold mb-2 text-[#1E90FF]">
              Our Vision
            </h2>
            <p className="text-gray-600">
              To become a leading platform that empowers micro and small
              entrepreneurs in the food and beverage industry with data-driven
              insights, enabling them to make smarter decisions and enhance
              their business success in a competitive market.
            </p>
          </div>
          <div className="md:w-64">
            {/* <img
              src="https://via.placeholder.com/150"
              alt="Our Values"
              className="rounded-lg mb-4"
            /> */}
            <h2 className="text-2xl font-semibold mb-2 text-[#1E90FF]">
              Our Values
            </h2>
            <p className="text-gray-600">
              Integrity, innovation, and customer-centricity are at the heart of
              everything we do.
            </p>
          </div>
        </div>
        <h1 className="text-3xl font-bold mb-4 mt-8 text-gray-800">Team</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 m-4">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow-md"
          >
            <img
              src={member.image}
              alt={member.name}
              className="w-32 h-32 rounded-full mb-2 object-cover"
            />
            <h2 className="text-xl font-semibold text-[#1E90FF]">
              {member.name}
            </h2>
            <p className="text-gray-600">{member.position}</p>
          </div>
        ))}
      </div>

      <Footer></Footer>
    </div>
  );
};

export default About;
