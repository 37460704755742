import React, { useEffect, useState } from "react";
import { auth, provider } from "../firebase";
import { signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore"; // Firestore functions
import { useNavigate } from "react-router-dom";

// Initialize Firestore
const db = getFirestore();

const SignInAndStore = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Function to initialize user data in Firestore
  const initializeUserData = async (uid, email) => {
    if (!uid || !email) return;

    const userDocRef = doc(db, "users", uid); // Reference to the Firestore document
    try {
      const userDocSnap = await getDoc(userDocRef); // Check if the document exists
      if (userDocSnap.exists()) {
        console.log(`User document already exists for UID: ${uid}`);
      } else {
        // Create the document with token: 3 and email
        await setDoc(userDocRef, {
          token: 3,
          email: email,
          role: "user",
          createdAt: new Date().toISOString(),
        });
        console.log(`New user document created for UID: ${uid}`);
      }
    } catch (error) {
      console.error("Error initializing user data:", error);
    }
  };

  const handleClick = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Signed in user:", result.user);
      setUser(result.user); // Set the logged-in user

      // Initialize user document in Firestore
      await initializeUserData(result.user.uid, result.user.email);
      navigate("/Dashboard");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        console.log("The sign-in popup was closed by the user.");
      } else {
        console.error("An unexpected error occurred during sign-in:", error);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Set the user if logged in
        await initializeUserData(currentUser.uid, currentUser.email); // Initialize Firestore document
      } else {
        setUser(null); // Reset user if logged out
      }
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
  }, []);

  return (
    <div>
      {user ? (
        <div className="items-center text-center m-8">
          <p className="text-white font-bold">Enjoy The Experience Here</p>
          <button
            className="my-6 mx-auto py-3 relative inline-block px-8 py-3 text-lg font-semibold text-white bg-gradient-to-r from-[#1E90FF] to-[#1278de] rounded-lg shadow-lg hover:from-[#70b8ff] hover:to-[#1E90FF] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 before:absolute before:-inset-1 before:bg-gradient-to-r before:from-[#1E90FF] before:to-[#62a6ea] before:rounded-lg before:blur-lg before:opacity-20 hover:before:opacity-40"
            onClick={() => {
              navigate("/Dashboard");
            }}
          >
            Dashboard
          </button>
        </div>
      ) : (
        <div className="items-center text-center m-8">
          <p className="text-white font-bold">
            Please sign in to access this feature and enjoy a personalized
            experience!
          </p>

          <button
            className="my-6 mx-auto py-3 relative inline-block px-8 py-3 text-lg font-semibold text-white bg-gradient-to-r from-[#1E90FF] to-[#1278de] rounded-lg shadow-lg hover:from-[#70b8ff] hover:to-[#1E90FF] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 before:absolute before:-inset-1 before:bg-gradient-to-r before:from-[#1E90FF] before:to-[#62a6ea] before:rounded-lg before:blur-lg before:opacity-20 hover:before:opacity-40"
            onClick={() => handleClick().catch(console.error)}
          >
            Sign In With Google
          </button>
        </div>
      )}
    </div>
  );
};

export default SignInAndStore;
