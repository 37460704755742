import { useState, useEffect } from "react";

const SurroundingPlaces = ({ places }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen width to determine if it's mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust based on your breakpoint
    };

    // Run on initial render
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: isMobile ? "1fr" : "repeat(3, 1fr)", // 1 column on mobile, 3 columns otherwise
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "1200px", // Center the grid
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <h2
        className=" w-full text-2xl md:text-4xl font-semibold text-[#1E90FF] pb-4 font-montserrat relative
    before:absolute before:-inset-1 before:rounded-sm before:blur-lg before:bg-[#1E90FF] before:opacity-20 before:content-['']"
        style={{
          // gridColumn: "1 / -1", // Span the entire width of the grid
          textAlign: "center",
          fontSize: isMobile ? "24px" : "32px",

          fontFamily: "montserrat",
        }}
      >
        Surrounding Places
      </h2>

      {places.map((place, index) => (
        <div
          key={index}
          style={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <img
            src={place.featured_image}
            alt={place.name}
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
          <h3 style={{ marginTop: "10px" }}>{place.name}</h3>
          <p>{place.address}</p>
        </div>
      ))}
    </div>
  );
};

export default SurroundingPlaces;
