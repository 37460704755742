import React from "react";
import { BarChart3, MapPin, SmilePlus } from "lucide-react";

export default function KeyFeaturesIconVer() {
  const features = [
    {
      icon: BarChart3,
      title: "Hyper Localized Data Driven Insights",
      description:
        "In-depth analysis of business locations within a 4 km radius, using AI to provide accurate recommendations, such as whether a buffet or café is more suitable for that area.",
    },
    {
      icon: SmilePlus,
      title: "Sentiment Analysis",
      description:
        "AI processes consumer reviews to identify issues (negative reviews) and best practices (positive reviews), helping business owners adjust their strategies to market needs.",
    },
    {
      icon: MapPin,
      title: "Business Location Search Platform",
      description:
        "Provides easy access to available locations for rent from various partners, facilitating the search and selection of the ideal business location.",
    },
  ];

  return (
    <section className="w-full bg-black text-white px-4 py-16">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-blue-400 text-center mb-16">
          Key Features of Lokavo
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div
                key={index}
                className="flex flex-col items-center text-center"
              >
                <div className="mb-6 w-24 h-24 rounded-full bg-blue-600 flex items-center justify-center">
                  <Icon className="w-12 h-12 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-blue-400 mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-300 leading-relaxed max-w-sm">
                  {feature.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
