import React from "react";
import AdminNavbar from "../component/AdminNavbar";
import Footer from "../component/PageFooter";
import { useAdminCheck } from "../helper/CheckAuth";
import AdminStats from "../component/AdminStats";
import LoadingScreen from "../component/Loading";

const AdminDashboard = () => {
  const { isAdmin, loading } = useAdminCheck();
  return (
    <div>
      {loading ? (
        <LoadingScreen></LoadingScreen>
      ) : (
        // <div className="w-full h-full text-center items-center text-white my-auto mx-auto text-4xl animate-bounce">
        //   Loading
        // </div>
        <div className="flex w-full h-screen">
          <AdminNavbar></AdminNavbar>
          <AdminStats></AdminStats>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
