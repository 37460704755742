import React, { useState, useEffect } from "react";

const BenefitCarousel = () => {
  const features = [
    {
      title: "Fast",
      description: "Get insights instantly with rapid analysis.",
    },
    {
      title: "Flexible",
      description: "Adaptable solutions tailored to your business needs.",
    },
    {
      title: "Accurate",
      description: "Reliable and precise data-driven recommendations.",
    },
    {
      title: "Easy",
      description: "Intuitive and easy-to-use platform.",
    },
    {
      title: "Affordable",
      description: "Cost-effective intelligence for every business.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 800);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [features.length]);

  // Manual controls
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? features.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative mt-8 text-center text-gray-200">
      {/* Feature Content */}
      <div className="flex flex-col items-center">
        <span className="text-2xl md:text-4xl font-bold text-blue-500 font-montserrat">
          {features[currentIndex].title}
        </span>
        <p className="text-md md:text-2xl">
          {features[currentIndex].description}
        </p>
      </div>

      {/* Previous and Next Buttons */}
      <button
        onClick={handlePrev}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-700 transition duration-300"
      >
        &#8249;
      </button>
      <button
        onClick={handleNext}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-700 transition duration-300"
      >
        &#8250;
      </button>

      {/* Pagination Dots */}
      <div className="flex justify-center space-x-2 mt-4">
        {features.map((_, index) => (
          <span
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-3 w-3 rounded-full cursor-pointer ${
              index === currentIndex
                ? "bg-blue-500"
                : "bg-gray-400 hover:bg-blue-400"
            }`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default BenefitCarousel;
