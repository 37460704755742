import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase"; // Adjust path to your Firebase config
import Navbar from "../component/Navbar";
import Footer from "../component/PageFooter";
import Pagination from "../component/Pagination";
import LoadingScreen from "../component/Loading";

function PublishedArticles() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 15;

  useEffect(() => {
    const fetchPublishedArticles = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "articles"),
          where("status", "==", "published")
        );
        const querySnapshot = await getDocs(q);
        const articlesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Default sort by date (most recent first)
        const sortedArticles = articlesData.sort((a, b) => {
          const aDate = a.createdAt?.seconds || 0;
          const bDate = b.createdAt?.seconds || 0;
          return bDate - aDate; // Descending order
        });

        setArticles(sortedArticles);
      } catch (error) {
        console.error("Error fetching published articles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPublishedArticles();
  }, []);

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  if (articles.length === 0) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <p className="text-gray-500 text-center">
          No published articles are available.
        </p>
      </div>
    );
  }

  const totalPages = Math.ceil(articles.length / articlesPerPage);
  const currentArticles = articles.slice(
    (currentPage - 1) * articlesPerPage,
    currentPage * articlesPerPage
  );

  return (
    <div>
      <Navbar></Navbar>

      <div className="max-w-7xl mx-auto p-8">
        <h1 className="text-3xl font-bold text-gray-500 mb-6">
          Published Articles
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {currentArticles.map((article) => (
            <div
              key={article.id}
              className="bg-white hover:bg-gray-400 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            >
              {article.image && (
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-48 object-cover"
                />
              )}
              <div className="p-4">
                <h2 className="text-lg font-bold text-gray-800 mb-2">
                  {article.title}
                </h2>
                <p
                  className="text-gray-600 text-sm leading-relaxed line-clamp-2"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                >
                  {/* {article.content} */}
                </p>
                <button
                  onClick={() => navigate(`/article/${article.id}`)}
                  className="mt-4 bg-blue-500 text-white text-sm font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition"
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>

      <Footer></Footer>
    </div>
  );
}

export default PublishedArticles;
