// firestoreService.js
import { db } from "../firebase";
import { collection, addDoc, getDocs, query } from "firebase/firestore";

// Add Q&A to Firestore
// export const addQA = async (question, answer) => {
//   try {
//     const docRef = await addDoc(collection(db, "qna"), {
//       question,
//       answer,
//       timestamp: new Date(),
//     });
//     console.log("Document written with ID: ", docRef.id);
//   } catch (e) {
//     console.error("Error adding document: ", e);
//   }
// };

// Get Q&A from Firestore
const qaCollection = collection(db, "qna");
const userCollection = collection(db, "usercount");

export const addQA = async (qaData) => {
  await addDoc(qaCollection, qaData);
};

export const addUserCount = async (count) => {
  await addDoc(userCollection, count);
};