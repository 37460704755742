// authUtils.js
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { auth } from "../firebase"; // Adjust the path to your Firebase config

export const logout = async () => {
  try {
    toast.success("Successfully logged out!");
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out:", error);
    toast.error("Failed to log out. Please try again.");
  }
};
