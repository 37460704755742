import React from "react";

const FormatText = ({ inputText = "" }) => {
  const formatTextToJSX = (text) => {
    // Safeguard against null or undefined
    if (!text) return <p className="text-gray-500">No content available.</p>;

    // Split the input text into lines
    const lines = text.split("\n");

    return lines.map((line, index) => {
      // Format headings (## will be heading)
      if (line.startsWith("## ")) {
        return (
          <h2 key={index} className="font-bold text-lg mt-4 text-white">
            {line.slice(3).trim()}
          </h2>
        );
      }

      // Format bullet points (* ** text **)
      if (line.startsWith("* **")) {
        const bulletContent = line.slice(2).trim();
        return (
          <li
            key={index}
            className="ml-4 list-disc text-white"
            dangerouslySetInnerHTML={{
              __html: bulletContent.replace(
                /\*\*(.*?)\*\*/g,
                "<strong>$1</strong>"
              ),
            }}
          />
        );
      }

      // Format bold text (** text **)
      const formattedLine = line.replace(
        /\*\*(.*?)\*\*/g,
        "<strong>$1</strong>"
      );

      // Return other lines as paragraphs
      return (
        <p
          key={index}
          className="my-2 text-white"
          dangerouslySetInnerHTML={{ __html: formattedLine }}
        />
      );
    });
  };

  return <div>{formatTextToJSX(inputText)}</div>;
};

export default FormatText;
