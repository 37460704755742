"use client";
import { useRef, useState, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
  Marker,
} from "@react-google-maps/api";
import TrafficConditionGraph from "./TrafficConditionGraph";
import SurroundingPlaces from "./SurroundingPlaces";
import { auth, db as firestore } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import SWOTAnalysis from "./SwotAnalysis";
import { BiSolidReport } from "react-icons/bi";

const libraries = ["places"];

const restApiKey = process.env.REACT_APP_REST_API_KEY;

export default function MapsLocationDisplay({ token, setToken }) {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const navigate = useNavigate();

  const [mapCenter, setMapCenter] = useState({
    lat: -7.577385515246401,
    lng: 110.82539729026065,
  });

  const handleMapClick = useCallback((event) => {
    if (event.latLng) {
      // Ensure latLng exists
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMapCenter(newPosition); // Update map center to the clicked position
    }
  }, []);

  // const [token, setToken] = useState(null); // Store trial chances

  const searchBoxRef = useRef(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [surroundingImage, setSurroundingImage] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showCategories, setShowCategories] = useState(false);
  const [strengthsAnalysis, setStrengthsAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [weaknessAnalysis, setWeaknessAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [opportunitiesAnalysis, setOpportunitiesAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [threatsAnalysis, setThreatsAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [recommendSummaryText, setRecommendSummaryText] = useState(
    "Click on StartAnalyze to generate a summary"
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey, // Add your API key here
    libraries,
  });

  const onSearchBoxLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const { geometry } = places[0];
      setMapCenter({
        lat: geometry.location.lat(),
        lng: geometry.location.lng(),
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onPlacesChanged();
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    console.log("Selected category:", category);
    setShowCategories(false);
    surroundingDetails(category); // Run postLocationData after selecting the category
    // generateSummaryText(category);
  };

  // const fetchtoken = async (userId) => {
  //   const userDocRef = doc(firestore, "users", userId);
  //   try {
  //     const docSnap = await getDoc(userDocRef);
  //     if (docSnap.exists()) {
  //       setToken(docSnap.data().token);
  //     } else {
  //       console.error("User document not found.");
  //       setToken(0); // Default to 0 if the document doesn't exist
  //     }
  //   } catch (error) {
  //     console.error("Error fetching trial chances:", error);
  //   }
  // };

  // // Listen to auth state changes and fetch trial chances
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       fetchtoken(user.uid);
  //     } else {
  //       setToken(null); // Reset trial chances if the user logs out
  //     }
  //   });

  //   return () => unsubscribe(); // Cleanup on component unmount
  // }, []);

  // Decrement trial chances in Firestore
  const decrementtoken = async (userId) => {
    if (token === 0) {
      console.warn("No trial chances left.");
      return;
    }

    const userDocRef = doc(firestore, "users", userId);
    try {
      const newtoken = token - 1;
      await updateDoc(userDocRef, { token: newtoken });
      setToken(newtoken); // Update local state
    } catch (error) {
      console.error("Error decrementing trial chances:", error);
    }
  };

  //Increment Token if Error

  const incrementtoken = async (userId) => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      const newtoken = token + 1;
      await updateDoc(userDocRef, { token: newtoken });
      setToken(newtoken); // Update local state
      console.log("token ince");
    } catch (error) {
      console.error("Error incrementing trial chances:", error);
    }
  };

  // Start Analyze function
  const startAnalyze = async () => {
    setShowCategories(true);
  };

  // const startAnalyze = () => {
  //   setShowCategories(true); // Show category buttons when "Start Analyze" is clicked
  // };

  const generateSummaryText = async (category) => {
    // setStrengthsAnalysisText(null);

    console.log(category);

    const dataSummary = {
      arglatitude: mapCenter.lat,
      arglongitude: mapCenter.lng,
      argMainCategory: `${category}`,
    };

    try {
      const response = await fetch(
        "https://lokavo-backend-dev-306419024546.us-central1.run.app/modelling_results",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-API-KEY": restApiKey,
          },
          body: JSON.stringify(dataSummary),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.message || `Error: ${response.statusText}`
        );
      }

      const result = await response.json();

      const business_recommendation = result.business_recommendation;
      const opportunities = result.opportunities;
      const strengths = result.strengths;
      const threats = result.threats;
      const weakness = result.weaknesses;

      setStrengthsAnalysisText(strengths);
      setWeaknessAnalysisText(weakness);
      setOpportunitiesAnalysisText(opportunities);
      setThreatsAnalysisText(threats);
      setRecommendSummaryText(business_recommendation);

      setResult(true);
    } catch (error) {
      console.error("Error:", error);

      setError(error.message || "Something went wrong.");
    } finally {
      setLoadingSummary(false);
      setShowSummary(true);
    }
  };

  const surroundingDetails = async (category) => {
    setLoadingSummary(true);
    setError(null);
    setResult(false);
    setShowSummary(false);
    setLoading(true);
    setError(null);
    setApiResponse(null);

    console.log("Using selected category:", category);

    const data = {
      arglatitude: mapCenter.lat,
      arglongitude: mapCenter.lng,
      argcolors: ["white", "white", "white"],
    };

    try {
      const response = await fetch(
        "https://lokavo-backend-dev-306419024546.us-central1.run.app/surroundings_details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-API-KEY": restApiKey,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.message || `Error: ${response.statusText}`
        );
      }

      const result = await response.json();

      const placesData = result.traffic_details.map((item) => ({
        featured_image: item.featured_image,
        name: item.name,
        address: item.address,
      }));

      if (placesData.length > 0) {
        setSurroundingImage(placesData);
      }

      setApiResponse(placesData);

      const vizData = result.viz;
      if (vizData) {
        setImageData(vizData);
      }

      await generateSummaryText(category);

      // if (auth.currentUser) {
      await decrementtoken(auth.currentUser.uid); // Decrement trial chances
      // }

      setResult(true);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* <APIProvider apiKey={apiKey}> */}
      <h1 className="text-gray-400 text-3xl p-8 font-montserrat">
        Getting started is easy! <br></br>Simply search for your business
        location or tap directly on the map below to begin.
      </h1>
      <div className="flex-grow">
        <form className="max-w-md mx-auto mb-4 mt-8" onSubmit={handleSubmit}>
          <div className="relative">
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                type="search"
                ref={inputRef}
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for places..."
                required
              />
            </StandaloneSearchBox>
            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm px-4 py-2"
            >
              Search
            </button>
          </div>
        </form>

        <div className="h-[80vh] w-full relative z-0 mt-4">
          <GoogleMap
            zoom={15}
            center={mapCenter}
            streetViewControl={false}
            mapContainerStyle={{ height: "100%", width: "100%" }}
            mapTypeControl={false}
            onClick={handleMapClick}
          >
            <Marker position={mapCenter} />
          </GoogleMap>
        </div>

        {token === 0 && (
          <p className="text-center mt-4 px-4 text-[#ff3f3f]  font-montserrat text-3xl">
            Your trial has ended. Please purchase tokens to continue using this
            feature. Thank you! See out our
            <ul
              href="/pricing"
              className="text-blue-400 hover:text-blue-300 transition-colors duration-200 underline underline-offset-2"
            >
              pricing detail
            </ul>
          </p>
        )}

        <div className="flex flex-col md:flex-row justify-center items-center text-center md:text-left mt-4">
          <h1 className="text-gray-400 text-2xl px-8">
            Once you've set your business location, click this button to
            generate your business insights!
          </h1>
          <button
            onClick={
              startAnalyze
              // handleUserCount
            }
            className={`my-6  py-3 relative inline-block px-8 text-lg font-semibold text-white rounded-lg shadow-lg transition duration-300 ease-in-out transform
              ${
                loading || token === 0
                  ? "bg-gray-400 cursor-not-allowed before:bg-gray-400 before:opacity-40"
                  : "bg-gradient-to-r from-[#1E90FF] to-[#1278de] hover:from-[#70b8ff] hover:to-[#1E90FF] before:bg-gradient-to-r before:from-[#1E90FF] before:to-[#62a6ea] before:opacity-20 hover:before:opacity-40 hover:-translate-y-1 hover:scale-105"
              }
            `}
            disabled={token === 0 || loading}
          >
            {loading ? "Analyzing..." : "Start Analyze"}
          </button>
        </div>

        {showCategories && (
          <div className="text-white text-center font-bold">
            <p1>Silikan Pilih Kategori Bisnis Anda Terlebih Dahulu:</p1>
          </div>
        )}

        {showCategories && (
          <div className="flex flex-col sm:flex-row  justify-center gap-4 mt-4">
            {[
              "Rumah Makan",
              "Kafe & Kedai Kopi",
              "Street Food",
              "Gerai Dessert",
              "Wedangan atau Angkringan",
            ].map((category) => (
              <button
                key={category}
                onClick={() => handleCategorySelect(category)}
                className={`px-4 py-2 rounded-md text-white  ${
                  selectedCategory === category
                    ? "bg-blue-800"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        )}
      </div>
      {result && imageData && (
        <div className="text-center text-white items-center">
          <div className="flex justify-center space-x-0">
            {/* <h3 className="text-center w-full text-4xl font-montserrat text-[#1E90FF] pb-4 pt-4">
              Ta-da! Here are your results!
            </h3> */}

            <BiSolidReport className="w-full text-white text-5xl items-center mb-4 mt-4"></BiSolidReport>
          </div>

          {/* <pre>{JSON.stringify(apiResponse, null, 2)}</pre> */}
          <TrafficConditionGraph base64String={imageData} />

          <SurroundingPlaces places={surroundingImage} />
        </div>
      )}
      {loadingSummary && (
        <h1 className="text-center text-3xl md:text-4xl font-bold text-blue-400 pb-6 pt-6 animate-bounce">
          Analyzing your data, please hold on a moment.
        </h1>
      )}
      {/* if (!loadingSummary) */}

      {showSummary && (
        <div className="p-16">
          <SWOTAnalysis
            strengthsAnalysis={strengthsAnalysis}
            weaknessAnalysis={weaknessAnalysis}
            opportunitiesAnalysis={opportunitiesAnalysis}
            threatsAnalysis={threatsAnalysis}
            recommendSummaryText={recommendSummaryText}
          ></SWOTAnalysis>
        </div>
      )}
      {error && (
        <div className="text-center text-red-500 mt-4">
          <h3 className="text-lg font-bold">
            Sorry there's something wrong, please try again
          </h3>
        </div>
      )}
    </div>
  );
}
