import React from "react";

import LokavoBanner from "../assets/lokavo_banner.png";

import BenefitCarousel from "./Benefit";
import KeyFeaturesIconVer from "./KeyFeaturesIconVer";

const Hero = ({ scrollToMaps }) => {
  return (
    <div className="text-white items-center">
      <img src={LokavoBanner} className="p-4 md:p-16" alt="banner"></img>
      <div className="p-4 md:pl-16 md:pr-16 w-full mx-auto text-start flex flex-col">
        <p className="text-gray-500 md:text-4xl text-xl font-bold md:pl-4 pl-2 text-start">
          Business Intelligence Platform for F&B in Indonesia that is
        </p>
        <BenefitCarousel></BenefitCarousel>

        <KeyFeaturesIconVer></KeyFeaturesIconVer>

        <button
          onClick={scrollToMaps}
          className="my-6 mx-auto py-3 relative inline-block px-8 py-3 text-lg font-semibold text-white bg-gradient-to-r from-[#1E90FF] to-[#1278de] rounded-lg shadow-lg hover:from-[#70b8ff] hover:to-[#1E90FF] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 before:absolute before:-inset-1 before:bg-gradient-to-r before:from-[#1E90FF] before:to-[#62a6ea] before:rounded-lg before:blur-lg before:opacity-20 hover:before:opacity-40"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Hero;
