import React from "react";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { PlusCircle } from "lucide-react";
import { db } from "../firebase";
import { useAdminCheck } from "../helper/CheckAuth";
import AdminNavbar from "../component/AdminNavbar";
import ArticleTable from "../component/ArticleTable";

export default function ArticlesPage() {
  const { isAdmin, loading } = useAdminCheck();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "articles"));
        const articlesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setArticles(articlesData);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    if (isAdmin) {
      fetchArticles(); // Only fetch articles if the user is an admin
    }
  }, [isAdmin]); // Dependency on isAdmin to trigger fetch when it becomes true

  return (
    <div className="flex w-full h-screen">
      <AdminNavbar />
      <ArticleTable></ArticleTable>
    </div>
  );
}
