import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Or use `next/navigation` for Next.js
import { auth, db } from "../firebase"; // Import your Firebase auth instance
import { getDoc, doc } from "firebase/firestore";

export function useAuth(redirectPath = "/Home") {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const navigate = useNavigate(); // Adjust to your routing setup

  useEffect(() => {
    // Listen to authentication state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        if (redirectPath) navigate(redirectPath); // Redirect unauthenticated users
      }

      // Set initial load as complete after auth check
      setInitialLoadComplete(true);
    });

    return unsubscribe; // Cleanup the listener on component unmount
  }, [navigate, redirectPath]);

  return { isAuthenticated, initialLoadComplete };
}

export const useAdminCheck = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Subscribe to authentication state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        console.log("User is not logged in. Redirecting to /home.");
        navigate("/home");
        setLoading(false);
        return;
      }

      try {
        console.log(`Authenticated user: ${user.uid}. Checking role...`);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();

        if (userData?.role === "admin") {
          console.log("User is an admin. Access granted.");
          setIsAdmin(true);
        } else {
          console.log("User is not an admin. Redirecting to /dashboard.");
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error checking admin role:", error);
        navigate("/dashboard"); // Redirect to dashboard on error
      } finally {
        setLoading(false);
      }
    });

    // Cleanup the listener on component unmount
    return () => {
      console.log("Cleaning up auth state listener.");
      unsubscribe();
    };
  }, [navigate]);

  return { isAdmin, loading };
};
