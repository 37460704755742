import React from "react";

export default function ComingSoon() {
  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-slate-900 to-black py-16 mt-16">
      {/* Animated background effect */}
      <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent" />

      {/* Content */}
      <div className="relative max-w-6xl mx-auto px-4 text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-blue-400 mb-6 animate-fade-in">
          Coming Soon: Cari Lapak
        </h2>

        <p className="text-gray-300 text-sm md:text-base leading-relaxed max-w-4xl mx-auto">
          Layanan yang dirancang untuk memudahkan pelaku Usaha Mikro, Kecil, dan
          Menengah (UMKM) dalam menemukan dan menyewa lokasi usaha yang
          strategis. Melalui platform ini, pengguna dapat mengakses informasi
          detail mengenai ketersediaan lapak, fasilitas yang ditawarkan, foto
          lokasi, detail harga sewa, serta informasi pemilik atau pengelola
          lapak.
        </p>

        {/* Decorative elements */}
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 w-1/2 h-px bg-gradient-to-r from-transparent via-blue-500 to-transparent" />
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 w-32 h-1 bg-blue-500/20 blur-sm" />
      </div>

      {/* Inline styles for keyframes */}
      <style>
        {`
          @keyframes fade-in {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }
          .animate-fade-in {
            animation: fade-in 1s ease-out forwards;
          }
          .bg-grid-white {
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
                              linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
          }
        `}
      </style>
    </div>
  );
}
