import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { db, storage } from "../firebase"; // Your Firebase config
import { deleteObject } from "firebase/storage";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the default Quill theme
import ImageUploader from "quill-image-uploader";
import { v4 as uuidv4 } from "uuid";
import { NavLink, useNavigate } from "react-router-dom";
import { BadgeX } from "lucide-react";

// Register the image uploader module
Quill.register("modules/imageUploader", ImageUploader);

function ArticleEditor({ user }) {
  const { documentId } = useParams(); // Get the documentId from the URL
  const [title, setTitle] = useState("");
  const [alias, setAlias] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [status, setStatus] = useState("draft"); // Default to "draft"
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [existingImage, setExistingImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(null);

  const navigate = useNavigate();

  // Fetch article data if editing
  useEffect(() => {
    const fetchArticle = async () => {
      if (documentId) {
        setLoading(true);
        try {
          const articleRef = doc(db, "articles", documentId);
          const articleSnap = await getDoc(articleRef);
          if (articleSnap.exists()) {
            const articleData = articleSnap.data();
            setTitle(articleData.title || "");
            setAlias(articleData.alias || "");
            setContent(articleData.content || "");
            setStatus(articleData.status || "draft");
            setExistingImage(articleData.image || null);
            setPreviewImage(articleData.image || null); // Show existing image
          }
        } catch (error) {
          console.error("Error fetching article:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchArticle();
  }, [documentId]);

  const handleImageUpload = async () => {
    if (!image) return; // Use existing image if no new one uploaded

    const uniqueFileName = `${uuidv4()}.${image.name.split(".").pop()}`;

    const imageRef = ref(storage, `articles/${uniqueFileName}`);
    await uploadBytes(imageRef, image);
    return await getDownloadURL(imageRef);
  };

  const deleteImageFromStorage = async (imageUrl) => {
    try {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      console.log("Image deleted from storage");
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleRemoveImage = () => {
    setImageToRemove(existingImage || null); // Save the URL of the image to be removed
    setExistingImage(null);
    setImage(null);
    setPreviewImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Delete the existing image if it's removed
      if (imageToRemove) {
        deleteImageFromStorage(imageToRemove);
      }

      // Upload new image if one is selected
      const imageUrl = await handleImageUpload();

      const articleData = {
        title,
        alias,
        content,
        image: imageUrl || existingImage, // Use the new image URL or null if removed
        status,
        authorId: user.email,
        updatedAt: serverTimestamp(),
      };

      if (documentId) {
        // Update existing article
        const articleRef = doc(db, "articles", documentId);
        await updateDoc(articleRef, articleData);
        alert("Article updated successfully!");
        navigate("/articlelist");
      } else {
        // Add new article
        await addDoc(collection(db, "articles"), {
          ...articleData,
          createdAt: serverTimestamp(),
        });
        alert("Article saved successfully!");
        navigate("/articlelist");
      }
    } catch (error) {
      console.error("Error saving article:", error);
      alert("Failed to save article. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("image");
    setImageToRemove(existingImage);
    console.log("remove exist");
    setImage(file);
    setPreviewImage(URL.createObjectURL(file)); // Preview the selected image
  };

  // Quill modules configuration
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      ["image", "code-block", "link", "video"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return (
    <div className="max-w-5xl min-h-screen mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      {loading ? (
        <p>Loading</p>
      ) : (
        <div>
          <h2 className="text-2xl font-bold mb-6">
            {documentId ? "Edit Article" : "Write a New Article"}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Title
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter article title"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Author Alias
              </label>
              <input
                type="text"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter author alias"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Content
              </label>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                className="bg-white"
                modules={modules}
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none"
              />
              {previewImage && (
                <div className="relative max-w-fit mt-4 max-h-40">
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="max-h-40 rounded-lg shadow-md"
                  />
                  <button
                    onClick={handleRemoveImage}
                    className="absolute top-1 right-1 bg-gray-300 text-red-500  rounded-md hover:bg-red-300"
                  >
                    <BadgeX></BadgeX>
                  </button>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Status
              </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="draft">Draft</option>
                <option value="published">Publish</option>
              </select>
            </div>

            <div className="flex gap-4">
              <button
                type="button"
                disabled={isSubmitting}
                className={`w-xl py-2 px-4 text-white font-semibold rounded-lg shadow-md ${
                  isSubmitting
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-red-500 hover:bg-red-800"
                }`}
                onClick={() => {
                  navigate("/articlelist");
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-xl py-2 px-4 text-white font-semibold rounded-lg shadow-md ${
                  isSubmitting
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-black hover:bg-blue-600"
                }`}
              >
                {isSubmitting
                  ? documentId
                    ? "Updating..."
                    : "Saving..."
                  : documentId
                  ? "Update Article"
                  : "Save Article"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ArticleEditor;
