// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import {
//   Users,
//   FileText,
//   LayoutDashboard,
//   LogOut,
//   Settings,
// } from "lucide-react";
// import Logo from "../assets/lokavologo.png";
// import { logout } from "../utils/AuthUtils";
// import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
// import toast, { Toaster } from "react-hot-toast";

// const AdminNavbar = () => {
//   const [nav, setNav] = useState(false);

//   const handleNav = () => {
//     setNav(!nav);
//   };

//   return (
//     <>
//       <div className="md:hidden flex items-center justify-between bg-zinc-950 px-6 py-4">
//         <div className="flex items-center space-x-2">
//           <img src={Logo} className="h-10" alt="Logo" />
//           <h3 className="font-bold text-white">LOKAVO</h3>
//         </div>
//         <button onClick={handleNav} className="text-white">
//           {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
//         </button>
//       </div>

//       <div
//         className={`md:w-1/6 flex h-screen md:flex-col bg-zinc-950 ${
//           nav ? "translate-x-0" : "-translate-x-full"
//         } md:translate-x-0`}
//       >
//         {/* Top Section */}
//         <div>
//           <Toaster position="top-right" reverseOrder={false} />

//           {/* Logo Section */}
//           <div className="p-6">
//             <div className="flex items-center space-x-2">
//               <img src={Logo} className="h-25" alt="Logo" />
//             </div>
//           </div>

//           <div className="h-px bg-gray-700 opacity-20"></div>

//           {/* Navigation Links */}
//           <nav className="flex-1 p-4 space-y-2">
//             <NavLink
//               to="/AdminDashboard"
//               className={({ isActive }) =>
//                 `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
//                   isActive ? "bg-white/10" : ""
//                 }`
//               }
//             >
//               <Settings className="mr-2 h-4 w-4" />
//               Admin Panel
//             </NavLink>
//             <NavLink
//               to="/UserManagement"
//               className={({ isActive }) =>
//                 `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
//                   isActive ? "bg-white/10" : ""
//                 }`
//               }
//             >
//               <Users className="mr-2 h-4 w-4" />
//               User Management
//             </NavLink>
//             <NavLink
//               to="/ArticleList"
//               className={({ isActive }) =>
//                 `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
//                   isActive ? "bg-white/10" : ""
//                 }`
//               }
//             >
//               <FileText className="mr-2 h-4 w-4" />
//               Article Management
//             </NavLink>
//             <NavLink
//               to="/dashboard"
//               className={({ isActive }) =>
//                 `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
//                   isActive ? "bg-white/10" : ""
//                 }`
//               }
//             >
//               <LayoutDashboard className="mr-2 h-4 w-4" />
//               Dashboard
//             </NavLink>
//           </nav>
//         </div>

//         {/* Bottom Section */}
//         <div className="p-4">
//           <div className="h-px bg-gray-700 opacity-20 my-4"></div>
//           <button
//             className="flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10"
//             onClick={logout}
//           >
//             <LogOut className="mr-2 h-4 w-4" />
//             Logout
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AdminNavbar;

import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import {
  Users,
  FileText,
  LayoutDashboard,
  Settings,
  LogOut,
} from "lucide-react";
import Logo from "../assets/lokavologo.png";
import { logout } from "../utils/AuthUtils";
import toast, { Toaster } from "react-hot-toast";

const AdminNavbar = () => {
  const [nav, setNav] = useState(true);
  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <div className="w-fit flex h-screen flex-col bg-zinc-950">
      <Toaster position="top-right" reverseOrder={false} />
      {/* Logo Section */}
      <div className="p-6">
        <div className="hidden md:inline flex items-center space-x-2">
          <img src={Logo}></img>
        </div>
      </div>

      <div className="h-px bg-gray-700 opacity-20"></div>

      {/* Navigation Links */}
      <nav className="flex-1 p-4 space-y-2">
        {/* <nav
        className={`flex-1 p-4 space-y-2 ease-in-out duration-500 z-50 ${
          !nav ? "left-0 w-[0%]" : "left-[-100%]"
        } md:left-0 `}
      > */}
        <NavLink
          to="/AdminDashboard"
          className={({ isActive }) =>
            `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
              isActive ? "bg-white/10" : ""
            }`
          }
        >
          <Settings className="mr-2 h-4 w-4" />
          <span className="hidden md:inline">Admin Panel</span>
        </NavLink>

        <NavLink
          to="/UserManagement"
          className={({ isActive }) =>
            `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
              isActive ? "bg-white/10" : ""
            }`
          }
        >
          <Users className="mr-2 h-4 w-4" />
          <span className="hidden md:inline">User Management</span>
        </NavLink>

        <NavLink
          to="/ArticleList"
          className={({ isActive }) =>
            `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
              isActive ? "bg-white/10" : ""
            }`
          }
        >
          <FileText className="mr-2 h-4 w-4" />
          <span className="hidden md:inline">Article Management</span>
        </NavLink>

        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            `flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10 ${
              isActive ? "bg-white/10" : ""
            }`
          }
        >
          <LayoutDashboard className="mr-2 h-4 w-4" />
          <span className="hidden md:inline">Dashboard</span>
        </NavLink>
      </nav>

      {/* Logout Section */}
      <div className="p-4">
        <div className="h-px bg-gray-700 opacity-20 my-4"></div>
        <button
          className="flex items-center w-full px-4 py-2 text-white rounded-md hover:bg-white/10"
          onClick={logout}
        >
          <LogOut className="mr-2 h-4 w-4" />
          <span className="hidden md:inline">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default AdminNavbar;
