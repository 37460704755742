// src/components/ui/card.js

import React from "react";

export const Card = ({ children, className = "" }) => {
  return (
    <div
      className={`bg-black shadow-md rounded-md overflow-hidden ${className}`}
    >
      {children}
    </div>
  );
};

export const CardContent = ({ children, className = "" }) => {
  return <div className={`p-4 ${className}`}>{children}</div>;
};
