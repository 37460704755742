import { useEffect, useState } from "react";
import { auth, db as firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { ImProfile } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { RiLogoutCircleRFill as LogoutIcon } from "react-icons/ri";
import { ShieldCheck } from "lucide-react";

import {
  BarChart3,
  MapPin,
  PuzzleIcon as PuzzlePiece,
  Star,
} from "lucide-react";
import { Card, CardContent } from "../component/Card";

import toast, { Toaster } from "react-hot-toast";
import { useAdminCheck } from "../helper/CheckAuth";
import { useNavigate } from "react-router-dom";

export default function ProfileSection({ token }) {
  const [email, setEmail] = useState(null);
  // const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const logout = async () => {
    try {
      toast.success("Successfully logged out!");
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Failed to log out. Please try again.");
    }
  };

  // Fetch user data from Firestore
  const fetchUserData = async (userId) => {
    setLoading(true); // Start loading
    const userDocRef = doc(firestore, "users", userId);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setEmail(data.email || "Email not available");
        const isAdmin = data.role === "admin";
        setIsAdmin(isAdmin);
        console.log(isAdmin);
        // setToken(data.token || 0);
      } else {
        console.error("User document not found.");
        setEmail("Email not available");
        // setToken(0); // Default to 0 if the document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  // Listen to authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user.uid); // Fetch user data if authenticated
      } else {
        setEmail(null); // Clear email if logged out
        // setToken(null); // Clear trial chances if logged out
        setLoading(false); // Stop loading if no user
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, []);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="flex flex-col p-4 bg-gray-800 rounded-lg shadow-md text-white max-w mt-8">
        <div className="flex flex-col items-start">
          <h2 className="text-2xl font-bold mb-4 text-start text-[#72afed]">
            Welcome Back!
          </h2>
          {isAdmin ? (
            <button
              onClick={() => navigate("/admindashboard")}
              className="bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm px-4 py-2 mb-4"
            >
              <div className="flex gap-2 items-center">
                {" "}
                <ShieldCheck className="text-green-600"></ShieldCheck>{" "}
                <span>Admin Panel</span>
              </div>
            </button>
          ) : null}
          <div className="flex items-center space-x-4 mb-4">
            <ImProfile color="[#1E90FF]" className="w-12 h-12" />
            {loading ? (
              <p className="text-lg font-semibold font-montserrat text-gray-400">
                Loading profile...
              </p>
            ) : (
              <div className="flex flex-col items-start space-y-4">
                {/* Email Section */}
                <div className="flex items-center space-x-2">
                  <HiOutlineMail className="w-8 h-8 text-[#1E90FF]" />
                  <p className="text-lg font-semibold font-montserrat">
                    {email}
                  </p>
                </div>

                {/* Token Section */}
                <div className="flex items-center space-x-2">
                  <MdOutlineGeneratingTokens className="w-8 h-8 text-[#1E90FF]" />
                  <p className="text-lg font-semibold font-montserrat">
                    {token} Tokens
                  </p>
                </div>
              </div>
            )}
          </div>
          <button
            onClick={logout}
            className="text-red-600 hover:text-red-800 text-lg font-semibold mt-4 self-start"
          >
            <div className="flex flex-row items-center justify-center space-x-2 text-xl">
              <LogoutIcon /> <p> Logout </p>
            </div>
          </button>
        </div>
      </div>

      {/* Report Type */}
      <section className="w-full px-4 py-12 bg-black text-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-12">
            With just one click, get these analysis tailored for your location.
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <Card className="group bg-transparent border-none">
              <CardContent className="p-6 text-center">
                <div className="mb-6 transform transition-transform duration-300 group-hover:scale-110">
                  <div className="w-20 h-20 mx-auto bg-blue-600/10 rounded-full flex items-center justify-center">
                    <MapPin className="w-10 h-10 text-blue-500" />
                  </div>
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-500">
                  Nearby Business
                </h3>
                <p className="text-gray-400">
                  Discover local businesses and opportunities in your area
                </p>
              </CardContent>
            </Card>

            <Card className="group bg-transparent border-none">
              <CardContent className="p-6 text-center">
                <div className="mb-6 transform transition-transform duration-300 group-hover:scale-110">
                  <div className="w-20 h-20 mx-auto bg-blue-600/10 rounded-full flex items-center justify-center">
                    <BarChart3 className="w-10 h-10 text-blue-500" />
                  </div>
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-500">
                  Traffic Conditions
                </h3>
                <p className="text-gray-400">
                  Real-time traffic analysis based on time of day
                </p>
              </CardContent>
            </Card>

            <Card className="group bg-transparent border-none">
              <CardContent className="p-6 text-center">
                <div className="mb-6 transform transition-transform duration-300 group-hover:scale-110">
                  <div className="w-20 h-20 mx-auto bg-blue-600/10 rounded-full flex items-center justify-center">
                    <PuzzlePiece className="w-10 h-10 text-blue-500" />
                  </div>
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-500">
                  SWOT Analysis
                </h3>
                <p className="text-gray-400">
                  Comprehensive analysis of strengths, weaknesses,
                  opportunities, and threats
                </p>
              </CardContent>
            </Card>

            <Card className="group bg-transparent border-none">
              <CardContent className="p-6 text-center">
                <div className="mb-6 transform transition-transform duration-300 group-hover:scale-110">
                  <div className="w-20 h-20 mx-auto bg-blue-600/10 rounded-full flex items-center justify-center">
                    <Star className="w-10 h-10 text-blue-500" />
                  </div>
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-500">
                  Business Recommendation
                </h3>
                <p className="text-gray-400">
                  Personalized recommendations based on your business needs
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>
      {/* Report Type */}
    </div>
  );
}
