import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Ensure this imports your Firebase configuration
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import AdminNavbar from "../component/AdminNavbar";
import { useAdminCheck } from "../helper/CheckAuth";
import Pagination from "../component/Pagination";
import { Pencil, SaveIcon, CircleX, Coins } from "lucide-react";

export default function UserManagement() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingUser, setEditingUser] = useState(null);
  const [edittoken, setEdittoken] = useState("");
  const [editRole, setEditRole] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const userPerPage = 10; // Set the number of articles per page

  // Calculate pagination data
  const totalPages = Math.ceil(users.length / userPerPage);
  const currentUsers = users.slice(
    (currentPage - 1) * userPerPage,
    currentPage * userPerPage
  );

  // Reference to the 'users' collection
  const usersCollection = collection(db, "users");

  const { isAdmin } = useAdminCheck();

  // Fetch users from Firestore
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(usersCollection);
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Default sort by email in ascending alphabetical order
      const sortedUsers = usersData.sort((a, b) => {
        const emailA = a.email?.toLowerCase() || ""; // Safeguard for undefined emails
        const emailB = b.email?.toLowerCase() || "";
        return emailA.localeCompare(emailB); // Alphabetical order
      });

      setUsers(sortedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  };

  const sortUsers = (field) => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);

    const sortedUsers = [...users].sort((a, b) => {
      const aValue = a[field] || ""; // Handle undefined/null fields
      const bValue = b[field] || "";

      if (direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    setUsers(sortedUsers);
  };

  // Update user in Firestore
  const updateUser = async (id) => {
    if (edittoken === "" || isNaN(edittoken)) {
      return alert("Trial chances must be a valid number!");
    }
    if (!editRole.trim()) {
      return alert("Role cannot be empty!");
    }

    const userDoc = doc(db, "users", id);
    try {
      await updateDoc(userDoc, {
        token: parseInt(edittoken, 10), // Ensure trial chances are stored as a number
        role: editRole,
      });
      alert("User updated successfully!");
      setEditingUser(null); // Exit edit mode
      fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  return (
    <div className="flex w-full h-screen">
      <AdminNavbar />

      <div className="flex flex-col min-h-screen p-8 bg-gray-100 w-5/6">
        <h1 className="text-2xl font-bold text-primary text-black mb-6">
          User Management
        </h1>
        {loading ? (
          <p className="text-gray-600">Loading users...</p>
        ) : (
          <div className="flex-grow overflow-x-auto">
            <table className="min-w-full bg-white border rounded-md shadow-md">
              <thead className="bg-black text-white">
                <tr>
                  <th
                    className="px-4 py-2 text-left cursor-pointer font-semibold"
                    onClick={() => sortUsers("uid")}
                  >
                    UID{" "}
                    {sortField === "uid" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="px-4 py-2 text-left cursor-pointer font-semibold"
                    onClick={() => sortUsers("email")}
                  >
                    Email{" "}
                    {sortField === "email" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="px-4 py-2 text-left cursor-pointer font-semibold"
                    onClick={() => sortUsers("role")}
                  >
                    Role{" "}
                    {sortField === "role" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="px-4 py-2 text-left cursor-pointer font-semibold"
                    onClick={() => sortUsers("token")}
                  >
                    Token{" "}
                    {sortField === "token" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th className="px-4 py-2 text-left cursor-pointer font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <tr key={user.id} className="border-b hover:bg-gray-100">
                    <td className="px-4 py-2 font-sans font-light">
                      {user.id}
                    </td>
                    <td className="px-4 py-2 font-sans font-semibold">
                      {user.email || "No Email"}
                    </td>
                    <td className="px-4 py-2 capitalize font-sans font-semibold">
                      {editingUser === user.id ? (
                        <select
                          value={editRole}
                          onChange={(e) => setEditRole(e.target.value)}
                          className="border px-2 py-1 rounded-md w-full"
                        >
                          <option value="">Select Role</option>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                        </select>
                      ) : (
                        user.role || (
                          <span className="text-gray-500 italic">No Role</span>
                        )
                      )}
                    </td>
                    <td className="px-4 py-2 font-sans font-semibold">
                      {editingUser === user.id ? (
                        <input
                          type="number"
                          value={edittoken}
                          onChange={(e) => setEdittoken(e.target.value)}
                          className="border px-2 py-1 rounded-md w-full"
                        />
                      ) : (
                        <div className="flex gap-2">
                          <p className="font-semibold">{user.token} </p>
                          <Coins className="text-blue-500"></Coins>
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {editingUser === user.id ? (
                        <>
                          <button
                            onClick={() => updateUser(user.id)}
                            className="bg-green-500 border border-black text-black p-2 rounded-md mr-2 mb-2 hover:bg-green-600"
                          >
                            <SaveIcon className="h-4 w-4" />
                          </button>
                          <button
                            onClick={() => setEditingUser(null)}
                            className="bg-red-500 border border-black text-black p-2 rounded-md hover:bg-red-600"
                          >
                            <CircleX className="h-4 w-4" />
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            setEditingUser(user.id);
                            setEdittoken(user.token || "");
                            setEditRole(user.role || "");
                          }}
                          className="bg-white border border-black p-2 rounded-md hover:bg-blue-600"
                        >
                          <Pencil className="h-4 w-4" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination Component at the bottom */}
        <div className="mt-auto">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}
