import React from "react";

import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Contact from "./pages/Contact";
import Home from "./pages/HomeP";
import About from "./pages/About";
import FAQ from "./pages/FrequentlyAskQ";
import PricingPage from "./pages/Pricing";
import Dashboard from "./pages/Dashboard";
import ArticleForm from "./pages/ArticleForm";
import ArticlesPage from "./pages/ArticleList";
import AdminDashboard from "./pages/AdminDashboard";
import UserManagement from "./pages/UserManagement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ArticlePage from "./pages/ArticlePage";
import PublishedArticles from "./pages/PublishedArticle";
import LoadingScreen from "./component/Loading";
import ArticleImageEditor from "./component/EditorWithImage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}></Route>
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/ArticleList" element={<ArticlesPage />} />
          <Route path="/ArticleForm" element={<ArticleForm />} />
          <Route path="/AdminDashboard" element={<AdminDashboard />} />
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Route for editing, read an article dynamically */}
          <Route path="/edit/:documentId" element={<ArticleForm />} />
          <Route path="article/:docId" element={<ArticlePage />} />
          <Route path="/article" element={<PublishedArticles />} />
          <Route path="/articleimage" element={<ArticleImageEditor />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
