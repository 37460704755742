import React from "react";
import Navbar from "./../component/Navbar";
import { FaInstagram, FaLinkedin, FaEnvelope } from "react-icons/fa";

const PrivacyPolicy = () => {
  return (
    <div className="bg-black items-center">
      <Navbar></Navbar>

      <div className="bg-white shadow-lg rounded-lg p-8 text-center m-4">
        <h1 className="text-4xl font-bold mb-4 text-gray-800">
          Privacy Policy
        </h1>
        <h2 className="text-2xl font-bold mb-4 text-gray-600">
          Effective Date: 22nd November 2024
        </h2>
        <p className="text-gray-600 text-lg mb-6 text-start">
          Lokavo values your privacy and is committed to protecting your
          personal information. This Privacy Policy outlines how we collect,
          use, store, and share your data when you use Lokavo's services,
          including our website, mobile applications, and related platforms
          <b> ("Services")</b>.
          <br />
          <br />{" "}
          <b>
            {" "}
            <i>
              {" "}
              By using our Services, you agree to the practices described in
              this Privacy Policy. If you do not agree, please do not use our
              Services.{" "}
            </i>
          </b>
          <br />
          <br />{" "}
          <b>
            {" "}
            1. Information We Collect We collect the following types of
            information when you use Lokavo:{" "}
          </b>
          <br />
          1.1 Personal Information Name Email address <br />
          1.2 Location Data Approximate Location: For general insights and
          regional analysis.{" "}
          <i>
            {" "}
            Precise Location: To provide hyperlocalized business insights and
            analytics.{" "}
          </i>{" "}
          <br />
          <br />
          <b>
            2. How We Use Your Information We use your information for the
            following purposes:{" "}
          </b>
          <br />
          To authenticate and manage your account. To personalize your
          experience and improve the accuracy of business insights. To enable
          location-based features for SWOT analysis and optimal business
          recommendations. <br />
          <br />
          <b>
            3. We Do Not Sell, Rent, or Share Your Personal Information Lokavo
            does not sell, rent, or share your personal information with third
            parties except as required to provide our Services or comply with
            legal obligations.{" "}
          </b>
          <br />
          <br />
          <b>
            4. Your Privacy Rights Depending on your location, you may have the
            following rights:
          </b>{" "}
          <br />
          4.1 Access: Request access to your personal data.
          <br />
          4.2 Correction: Request corrections to inaccurate data. <br></br>
          4.3 Deletion: Request the deletion of your data. <br />
          <br />
          <b>
            <i>
              To exercise these rights, please contact us at{" "}
              <a
                href="mailto:gptlokavo@gmail.com;"
                className="text-[#1E90FF] hover:text-[#3482d0ae]  font-semibold ml-1"
              >
                gptlokavo@gmail.com
              </a>{" "}
            </i>
          </b>
          <br />
          <br />
          <b>
            5. Contact Us If you have any questions, concerns, or feedback
            regarding this Privacy Policy or our data practices.
          </b>
          <br />
          <br />
          <h2 className=" font-bold text-[#25588c]">
            By using Lokavo, you acknowledge that you have read and understood
            this Privacy Policy and agree to its terms.
          </h2>
        </p>

        <div className="flex justify-around mt-8">
          {/* Instagram */}
          <a
            href="https://www.instagram.com/lokavo.id/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center"
          >
            <FaInstagram className="text-[#1E90FF] hover:text-[#3482d0ae] text-6xl mb-2" />
            <span className="text-gray-600 text-lg">Instagram</span>
          </a>

          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/company/lokavo-id/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center"
          >
            <FaLinkedin className="text-[#1E90FF] hover:text-[#3482d0ae]  text-6xl mb-2" />
            <span className="text-gray-600 text-lg">LinkedIn</span>
          </a>

          {/* Email */}
          <a
            href="mailto:gptlokavo@gmail.com;"
            className="flex flex-col items-center"
          >
            <FaEnvelope className="text-[#1E90FF] hover:text-[#3482d0ae]  text-6xl mb-2" />
            <span className="text-gray-600 text-lg">Email</span>
          </a>
        </div>

        <div className="mt-8">
          <p className="text-gray-600 text-lg">
            You can also drop us an email at:
            <a
              href="mailto:lokavo.gptlokavo@gmail.com;"
              className="text-[#1E90FF] hover:text-[#3482d0ae]  font-semibold ml-1"
            >
              gptlokavo@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
