import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import default theme

function ArticleImageEditor() {
  const [content, setContent] = useState(""); // State to store editor content
  const quillRef = useRef(null); // Reference to the Quill editor

  // Define custom modules for React-Quill
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }], // Header formatting
        ["bold", "italic", "underline", "strike"], // Text formatting
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        ["link", "image"], // Add link and image options
      ],
      handlers: {
        image: () => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.click();

          input.onchange = async () => {
            const file = input.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = () => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection();
                quill.insertEmbed(range.index, "image", reader.result); // Insert image
              };
              reader.readAsDataURL(file);
            }
          };
        },
      },
    },
  };

  // Define acceptable formats
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <div>
      <h2>Write an Article</h2>
      <ReactQuill
        ref={quillRef}
        value={content}
        onChange={setContent}
        modules={modules} // Add custom modules
        formats={formats} // Add acceptable formats
        theme="snow" // Specify theme
      />
      <button
        onClick={() => console.log(content)}
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
      >
        Log Content
      </button>
    </div>
  );
}

export default ArticleImageEditor;
