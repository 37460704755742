import React, { useState } from "react";

export default function FAQComponent() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is Lokavo?",
      answer:
        "Lokavo is a business intelligence platform that helps entrepreneurs and business owners analyze their chosen business location and competitor landscape.",
    },
    {
      question: "Who can use Lokavo?",
      answer:
        "Lokavo is designed for entrepreneurs, SMEs, and F&B businesses looking for location insights and competitor analysis in Indonesia.",
    },
    {
      question: "How does Lokavo help F&B businesses?",
      answer:
        "Lokavo provides detailed analysis of the location chosen by the business owner, offering insights that help make better decisions for growth and sustainability.",
    },
    {
      question: "Is Lokavo available across Indonesia?",
      answer:
        "No, currently Lokavo is only available for businesses across Surakarta City, Indonesia.",
    },
    {
      question: "What industries does Lokavo support?",
      answer:
        "Lokavo primarily supports F&B businesses, but its insights can be applied to a variety of industries that need location-based analysis.",
    },
    {
      question: "Is Lokavo's platform easy to use?",
      answer:
        "Yes, Lokavo's platform is designed to be intuitive and user-friendly, with minimal technical expertise required to navigate and interpret the data.",
    },
    {
      question: "Do I need to download any software?",
      answer:
        "Yes, Lokavo is an android application that can be downloaded on Google Play Store.",
    },
    {
      question: "How long does it take to analyze my chosen location?",
      answer:
        "The analysis process is quick and efficient, typically providing results within minutes of submitting your location details.",
    },
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="w-full px-4 py-12 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w ">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
          Frequently Asked Questions
        </h2>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 pb-4">
              {/* Question */}
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full text-left text-lg font-medium py-2 hover:text-blue-600 transition-colors"
              >
                {faq.question}
              </button>
              {/* Answer */}
              {openIndex === index && (
                <div className="text-gray-600 mt-2 transition-all duration-300 text-left">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Decorative elements */}
        <div className="absolute left-0 right-0 top-0 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent" />
        <div className="absolute left-0 right-0 bottom-0 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent" />
      </div>
    </section>
  );
}
