import React, { useState } from "react";
import { addQA } from "../helper/QAndAFirestoreService";
import toast, { Toaster } from "react-hot-toast";

const presetQuestions = [
  "Nama",
  "Alamat Email",
  "Fitur apa yang paling Anda harapkan ada dalam aplikasi seperti Lokavo?",
  "Bagaimana pengalaman Anda saat menggunakan Lokavo untuk pertama kali?",
  "Apakah informasi yang disediakan Lokavo sudah cukup membantu untuk keputusan bisnis Anda?",
];

const QAComponent = () => {
  const [answers, setAnswers] = useState(
    presetQuestions.map(() => "") // Initialize empty answers state
  );
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleAddQA = async () => {
    setLoading(true); // Start loading

    try {
      // Construct a single document object containing all questions and answers
      const qaData = {
        questions: presetQuestions,
        answers: answers,
        timestamp: new Date(), // Optional: Add timestamp
      };

      await addQA(qaData); // Save to Firestore

      toast.success("Jawaban berhasil disimpan!");
      setSubmitted(true); // Mark as submitted
    } catch (error) {
      console.log(error);
      toast.error("Gagal menyimpan jawaban. Coba lagi!");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="md:m-32 m-8">
      <Toaster position="top-right" reverseOrder={false} />

      <h2 className="text-4xl font-bold mb-4 text-[#1E90FF] text-center">
        q u e s t i o n n a i r e
      </h2>

      {submitted ? (
        <p className="text-green-500 font-semibold">
          Terima kasih, jawaban Anda telah berhasil disimpan!
        </p>
      ) : (
        presetQuestions.map((question, index) => (
          <div key={index} className="mb-4 ">
            <h3 className="font-semibold text-white mb-4">{question}</h3>
            <input
              type="text"
              placeholder="Tulis jawaban Anda"
              value={answers[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
              className="w-full p-2 border rounded-md"
            />
          </div>
        ))
      )}

      {!submitted && (
        <button
          onClick={handleAddQA}
          className={`bg-blue-600 text-white px-4 py-2 rounded-md mt-4 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={loading}
        >
          {loading ? "Menyimpan..." : "Submit Jawaban"}
        </button>
      )}
    </div>
  );
};

export default QAComponent;
