import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const TrafficConditionGraph = ({ base64String = "" }) => {
  const [isVisible, setIsVisible] = useState(true); // Track visibility of the image

  // Function to extract and clean the base64 image data safely
  const extractImageData = (str) => {
    if (!str || typeof str !== "string") return "";

    // Locate the 'base64,' part and extract the image data after it
    const startIndex = str.indexOf("base64,") + "base64,".length;
    if (startIndex <= "base64,".length - 1) return "";

    // Clean trailing whitespace or unwanted characters like '==', '=">', etc.
    return str
      .substring(startIndex)
      .trim()
      .replace(/[\s=">]+$/, "");
  };

  const extractedData = extractImageData(base64String);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {/* Flex container for heading and icon */}
      {/* <div className="flex items-center justify-center space-x-2"> */}
      <h2
        className="text-2xl md:text-4xl font-semibold text-[#1E90FF] pb-4 font-montserrat relative
          before:absolute before:-inset-1 before:rounded-sm before:blur-lg before:bg-[#1E90FF] before:opacity-20 before:content-['']"
      >
        Traffic conditions around the location based on the time of day.
      </h2>
      <button
        onClick={() => setIsVisible(!isVisible)} // Toggle visibility
        className="flex items-center text-[#1E90FF] hover:text-blue-700 transition duration-300"
      >
        {isVisible ? (
          <AiOutlineEyeInvisible size={32} title="Hide" />
        ) : (
          <AiOutlineEye size={32} title="Show" />
        )}
      </button>
      {/* </div> */}
      {isVisible && extractedData ? (
        <img
          src={`data:image/png;base64,${extractedData}`}
          alt="Base64"
          style={{ width: "auto", height: "auto" }}
        />
      ) : isVisible ? (
        <p>Invalid or missing Base64 data.</p>
      ) : null}
    </div>
  );
};

export default TrafficConditionGraph;
